/* eslint-disable react/jsx-indent-props */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-indent */
/* eslint-disable import/order */
import * as React from 'react';
import { FC, useEffect } from 'react';
import { TFunction } from 'i18next';
import HubspotForm from 'react-hubspot-form';
import TrustPilot from '../widgets/TrustPilot';
import * as rdd from 'react-device-detect';
import { WidthCardsSlider } from '../common/elements/Slider';

import images from '../../../utils/store/images';
import { ImageGallery } from '../../ui/imageGallery';
import {
  checkUtmCookie
} from '../ssu/ssu-utils.js';


const SectionDemo: FC<{ translation: TFunction }> = ({ translation }) => {
  const quotes = translation('quotes', {
    returnObjects: true,
  });
  

  useEffect(() => {
    setTimeout(function() {

    const cmpgn = document.getElementsByName('utm_campaign');
    const campaing_value = checkUtmCookie('campaign');
    // @ts-ignore-start
    if(campaing_value && cmpgn[0]) cmpgn[0].value = campaing_value ;

    const src = document.getElementsByName('utm_source');
    const source_value = checkUtmCookie('source') ;
    // @ts-ignore-start
    if(source_value && src[0])  src[0].value = source_value;

    const mdm = document.getElementsByName('utm_medium');
    const medium_value = checkUtmCookie('medium') ;
    // @ts-ignore-start
    if(medium_value && mdm[0]) mdm[0].value = medium_value;

    const trm = document.getElementsByName('utm_term');
    const term_value = checkUtmCookie('term') 
    // @ts-ignore-start
    if(term_value && trm[0]) trm[0].value = term_value;

    const cnt = document.getElementsByName('utm_content');
    const content_value = checkUtmCookie('content') 
    // @ts-ignore-start
    if(content_value && cnt[0]) cnt[0].value = content_value ;
  }, 2000);
   }, []);


  const {trust} = images;
  return (
    <section className="section-demo">
      <div className="container">
        <div className="columns is-desktop is-centered">
          <div className="column is-half-desktop">
            <div className="hbspt-form-wrapper has-background-white card white">
              <h1 className="title">{translation('headline')}</h1>
              <div className="trust">
                <TrustPilot />

                {Object.keys(trust).map((key) => {
                  const {
                    img,
                    className,
                    alt,
                    width,
                    height,
                    maxWidth,
                  } = trust[key];
                  return (
                    <ImageGallery
                      key={key}
                      name={img}
                      className={className}
                      alt={alt}
                      width={width}
                      height={height}
                      maxWidth={maxWidth}
                    />
                  );
                })}
              </div>

              <div className="hb-target hbspt-form" id="hbspt-form-id">
                <HubspotForm
                  portalId={translation('hsForm.portalId')}
                  formId={translation('hsForm.formId')}
                  loading={<div>Loading...</div>}
                />
              </div>
            </div>
          </div>
          <div className="column is-half-desktop">
            <div className="card slider-card">
              <div className="card-container">
                <WidthCardsSlider slides={quotes} />
              </div>
            </div>
          </div>
        </div>

        {rdd.isDesktop && (
          <div className="trust-desktop">
            <div className="trust-card">
              <TrustPilot />
            </div>

            {Object.keys(trust).map((key) => {
              const { img, className, alt, width, height, maxWidth } = trust[
                key
              ];
              return (
                <div key={key} className="trust-card">
                  <ImageGallery
                    name={img}
                    className={className}
                    alt={alt}
                    width={width}
                    height={height}
                    maxWidth={maxWidth}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
};

export { SectionDemo };
