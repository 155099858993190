import * as React from 'react';
import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { LocalizedPageProps } from '../utils/types';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import { SectionDemo } from '../components/partials/demo';

const IndexPage: FC<LocalizedPageProps> = ({ pageContext }) => {
  const { t } = useTranslation('demo');
  const { lang, alternateUrls } = pageContext;

  return (
    <Layout
      pageContext={pageContext}
      secondNav
      navClasses="transparent"
      mainClasses=" "
    >
      <SEO
        lang={lang}
        title={t('meta.title')}
        description={t('meta.description')}
        alternateUrls={alternateUrls}
      />
      <SectionDemo translation={t} />
    </Layout>
  );
};

export default IndexPage;
